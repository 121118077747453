@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.carousel-card {
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 180px;
    margin: 0 auto;
    min-height: 120px;
    padding: 10px;
}