.navbar {
	transition-property: all;
	transition-duration: 0.3s;
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition-delay: 0s;
	opacity: 1;
	.navbar-brand {
		.navbar-brand-logo-item {
			&:hover, &:focus {
				background-color: transparent !important;
			}
		}
		.navbar-item {
			.navbar-brand-logo {
				max-height: 3rem;
				margin-top: -8px;
			}
			@media all and (max-width: 1023px) {
				.navbar-brand-logo-item {
					.navbar-brand-logo {
						max-height: 2.5rem;
						margin-left: -10px;
					}
				}
			}
		}
		.navbar-burger {
			background-color: transparent;
			border: none;
		}
	}
	.navbar-menu {
		.navbar-end {

		}
		.navbar-item {

		}
	}
	&.transparent-navbar {
		background-color: transparent;
	}
	&.dark-navbar {
		background-color: #350473;
		color: #350473;
		box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.75);
	}
	&.navbar-decrease {
		padding-top: 0;
		padding-bottom: 0;
	}
	&.is-dark {
		.navbar-end {
			a.navbar-item {
				&:focus {
					background: transparent;
				}
			}
			& > {
				a.navbar-item {
					&:hover {
						background-color: transparent;
						color: #fff !important;
						font-weight: 600;
					}				
				}
			}
		}
	}
}