.contact-info-section {
  .contact-info {
    .svg-icon {
      display: inline-flex;
      display: -webkit-box; 
      margin-right: 1rem;
      svg {
        fill: #008000;
        width: 2rem;
      }
    }
    a {
      display: flex;
      vertical-align: middle;
      align-items: center;
      color: #47525e;
    }
  }
}


.center-or-div {
    position: relative;
    height: 100%;
    .vertical-line {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 50% 50%;
        padding: 1rem;
    }
    &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid #7E55F3;
    }
}

.tag-pill {
  border-radius: 2rem;
  font-size: 1rem;
  padding: 4px 10px;
  display: inline-block;
  margin: 8px;
  word-break: normal;
  color: #fff;
}

.send-message-section {

}
