.about-me-person-img {
    background: rgba(255, 255, 255, 0.5);
    // border: 10px solid transparent;
    box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.75);
    border-radius: 4px;
    img {
        border-radius: 4px;
    }
}


.content-wrapper {
    background-color: rgba(0, 88, 66, 0.7);
    border-radius: 5px;
}

.bg-aboutme {
    background-image: url('./../../images/deepakkumarjain_mic.jpeg');
    background-repeat: no-repeat;
    background-position: 100% 30%;
    background-size: cover;
}

@media all and (max-width: 768px) {
  .bg-aboutme {
    background-position: 85% 30%;
  }
    .empty-div-aboutme {
        height: 20rem;
    }
}