.bg-img {
  background: rgba(101, 25, 255, 0.58);
  // background-image: url("./../../images/homepagebanner.jpg");
  background-image: url("./../../images/ganesha_macbookpro.jpg");
  /* fallback */
  // background-image     : url("./../../images/homepagebanner.jpg"), linear-gradient(-145deg, rgba(101, 25, 255, 0.58), rgba(209, 53, 49, 0.58));
  // background-image  : url("./../../images/homepagebanner.jpg"), linear-gradient(-145deg, rgba(101, 25, 255, 0.58), rgba(209, 53, 49, 0.58));
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background: rgba(101, 25, 255, 0.58);
    // background: linear-gradient(-145deg, rgba(101, 25, 255, 0.58), transparent);
  }
}

.content {
  z-index: 1;
  .title {
    line-height: 1.6;
  }
  .subtitle {
    line-height: 1.4;
  }
}

.underline {
  position: relative;
  transition: all 0.2s ease-in-out;
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -0.14em;
    width: 0;
    height: 0.08em;
    animation: 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.5s 1 slideLineOutwards;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  &.white {
    &:before, &:after {
      background-color: #fff;
    }
  }
  &.dark-grey {
    &:before, &:after {
      background-color: #5A6978;
    }
  }
  &:before {
    left: calc(40%);
  }
  &:after {
    right: calc(40%);
  }
}

@keyframes slideLineOutwards {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 50%;
    opacity: 1;
  }
}
