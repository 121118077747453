@import-normalize;

@charset "utf-8";

// button filled primary type 1
$button-primary1-border-color: #976DD0;
$button-primary1-text-color: #fff;
$button-primary1-bg-color: #976DD0;
$button-primary1-border-radius: 5px;

// button filled primary type 2
$button-primary2-border-color: #fff;
$button-primary2-text-color: #343F4B;
$button-primary2-bg-color: #fff;
$button-primary2-border-radius: 5px;


// button outlint type 1
$button-outline-type1-border-color: #fff;
$button-outline-type1-text-color: #fff;
$button-outline-type1-bg-color: transparent; // mostly should be dark color for visibility
$button-outline-type1-border-radius: 5px;

// button outlint type 2
$button-outline-type2-border-color: #976DD0;
$button-outline-type2-text-color: #976DD0;
$button-outline-type2-bg-color: transparent;
$button-outline-type2-border-radius: 5px;

// navbar
$navbar-background-color: rgba(102, 25, 255, 0.34);
$navbar-background-color2: #50505B;
$logo-plain-color: rgba(72, 207, 173, 0.45);

// section
$section-title-color: #5A6978;
$section-subtitle-color: #969FAA;

// card
$card-img-border-radius: 5px;
$card-title-color: #47525E;
$card-desc-color: #8190A5;

// text color
$text1-color: #47525E;
$text2-color: #fff;

// misc
$banner-main-section-bg-color: rgba(102, 25, 255, 0.58);
$border-radius: 5px;
$image-bg-color: #E5E9F2;
$bg-color: #32CCBC;
$hr-color: #7E55F3;
$green-p: #13CE66;


// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap');

// Set your brand colors
// $purple: #8A4D76;
// $pink: #FA7C91;
// $brown: #757763;
// $beige-light: #D0D1CD;
// $beige-lighter: #EFF0EB;
$primary: #976DD0;
$border: #976DD0;

// Update Bulma's global variables
$family-sans-serif: "Lato", sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
// $primary: $purple;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// Update some of Bulma's component variables
// $body-background-color: $beige-lighter;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;
$body-min-width: 320px;


// footer 
$footer-padding:  0.5rem;
$footer-background-color: $green-p;

// card
$card-color: #8190A5;
$card-image-border: #e8e7e7;
$card-shadow: none;
$card-header-shadow: none;
$card-content-padding: 1.5rem 0;


// title
$title-color: #47525E;
$subtitle-color: #969FAA;

// form
$input-border-color :#8492A6;
$input-shadow: none;
$input-placeholder-color: #363636;


// navbar
$navbar-burger-color: #fff;
// $navbar-background-color: transparent;

// Import only what you need from Bulma
// @import "~bulma/bulma";
@import "../node_modules/bulma/bulma.sass";
// @import "../node_modules/bulma/bulma.sass";
// @import "../node_modules/bulma/sass/base/_all.sass";
// @import "../node_modules/bulma/sass/utilities/_all.sass";

// @import "../node_modules/bulma/sass/grid/_all.sass";
// @import "../node_modules/bulma/sass/layout/section.sass";
// @import "../node_modules/bulma/sass/form/_all.sass";
// @import "../node_modules/bulma/sass/elements/_all.sass";
// @import "../node_modules/bulma/sass/components/";

// @import "../node_modules/bulma/sass/base/_all.sass";
// @import "../node_modules/bulma/sass/elements/button.sass";
// @import "../node_modules/bulma/sass/elements/container.sass";
// @import "../node_modules/bulma/sass/elements/title.sass";
// @import "../node_modules/bulma/sass/form/_all.sass";
// @import "../node_modules/bulma/sass/components/navbar.sass";
// @import "../node_modules/bulma/sass/layout/hero.sass";
// @import "../node_modules/bulma/sass/layout/section.sass";


.line-height-0 {
    line-height: 0;
}

.is-border-white {
    border-color: $white;
}

.is-flex-wrap {
    flex-wrap: wrap;
}

.margin-top-0 {
    margin-top: 0 !important;
}

.margin-bottom-0point5 {
    margin-bottom: 0.8rem !important;
}
.margin-bottom-1 {
    margin-bottom: 1rem;
}

.margin-bottom-2 {
    margin-bottom: 2rem;
}

.margin-bottom-3 {
    margin-bottom: 3rem;
}

.card {
    .card-image {
        box-shadow: 4px 4px 8px 0 rgba(0,0,0,.1);
        figure {
            transition: all 0.3s ease-in-out;
        }
    }
    &:hover {
        .card-image {
            box-shadow: 1px 6px 10px 1px rgba(0, 0, 0, 0.2);
            figure {
                transform: scale(1.05);
            }
        }
    }
}

.has-text-dark-theme {
    color: #47525E;
}

.bg-9C27B0 {
    background-color: #9C27B0;
}

.bg-673AB7 {
    background-color: #673AB7;
}

.bg-3F51B5 {
    background-color: #3F51B5;
}

.bg-2196F3 {
    background-color: #2196F3;
}

.bg-03A9F4 {
    background-color: #03A9F4;
}

.bg-00BCD4 {
    background-color: #00BCD4;
}

.bg-969FAA {
    background-color: #969FAA;
}

.bg-image-white {
    background-image: url('./images/bg-tools.svg');
    background-repeat: repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.app {
    position: relative;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 30px;
}


@media all and (max-width: 991px) {
    html {
        font-size: 14px;
    }
}